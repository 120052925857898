/* @flow */

import { useContext, useMemo } from "react";
import { useLocation } from "react-router";
import { StoreInfoContext } from "entrypoint/shared";

const useOnFullMenuHiddenRoute = () => {
  const { routes } = useContext(StoreInfoContext);
  const { pathname } = useLocation();
  const formsPath = "/forms";
  const earnView = ((routes.earnViewSubpage && routes.earnViewSubpage.toggle) ||
  (routes.earnViewSubpage2 && routes.earnViewSubpage2.toggle)) && true;

  const FULL_MENU_HIDDEN_ROUTES = useMemo(() => ([
    routes.accountView.url,
    routes.offerListView.url,
    routes.newsListView.url,
    routes.suppliersView.url,
    routes.quizList.url,
    routes.quizHistory.url,
    routes.currentInfoView1.url,
    routes.currentInfoView2.url,
    routes.currentInfoView3.url,
    routes.currentInfoView4.url,
    routes.currentInfoView5.url,
    routes.currentInfoView6.url,
    formsPath,
    ...routes.formListView && routes.formListView.url ? [routes.formListView.url] : [],
    !earnView ? routes.earnView.url : undefined,
  ]), [routes]);

  return FULL_MENU_HIDDEN_ROUTES.some(route => pathname.includes(route));
};

export default useOnFullMenuHiddenRoute;
